import { CircularProgress, Grid, Typography, Box, Dialog } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultContainer from "../components/DefaultContainer";
import DefaultHeader from "../components/DefaultHeader";
import MoneyFormat from "../components/MoneyFormat";
import { cancelOrder, getDetails } from "../services/Transactions";
import qs from "query-string";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import Button from "@mui/material/Button";
import { getAllByPartnerID } from "../services/Review";
import ls from "local-storage";
import moment from "moment";
import 'moment/locale/id';
import Overlay from "../components/Overlay";
import DefaultTextField from "../components/DefaultTextField";
import Toast from "../components/Snackbar";

export default function HistoryDetailScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const [details, setDetails] = useState([]);
  const [subtotal, setSubtotal] = useState("");
  const [specialDiscount, setSpecialDiscount] = useState(0);
  const [totalSpecialDisc, setTotalSpecialDisc] = useState(0);
  const [programDisc, setProgramDisc] = useState(0);
  const [programTitle, setProgramTitle] = useState("");
  const [charge, setCharge] = useState(0);
  const [totalCharge, setTotalCharge] = useState(0);
  const [service, setService] = useState(0);
  const [totalService, setTotalService] = useState(0);
  const [tax, setTax] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalAfterRounding, setTotalAfterRounding] = useState(0);
  const [time, setTime] = useState("");
  const [pmName, setPmName] = useState("");
  const [status, setStatus] = useState("0");
  const [createReview, setReview] = useState([]);
  const [loading, setLoading] = useState(false);
  const [renderCounter, setRenderCounter] = useState(0)
  const [rounding, setRounding] = useState(0)
  const [overlayAccessDenied, setOverlayAccessDenied] = useState(false)
  const [bodyHeight, setBodyHeight] = useState(0)
  const [clickCancel, setClickCancel] = useState(false)
  const [openCancelNotes, setOpenCancelNotes] = useState(false)
  const [notes, setNotes] = useState("")
  const [toastOpen, setToastOpen] = useState(false)
  const [msg, setMsg] = useState("")
  const [toastStatus, setToastStatus] = useState(0)
  const [cancelConfirmation, setCancelConfirmation] = useState(false)
  const [trx, setTrx] = useState([])

  const bodyHeightRef = useRef()

  const disabledButton = {
    bgcolor: "gray",
    disabled: true, 
  }
  
  const enabledButton = {
    bgcolor: "#1FB0E6",
    disabled: false
  }

  const [buttonBehavior, setButtonBehavior] = useState(disabledButton)
  
  useEffect(() => {

    if(!ls.get("redux_localstorage_simple") || ls.get("sfToken") === null 
    || ls.get("sfToken").includes("dC9lNEx5UHNmb2VIWURoMzQrZDNvQmRGMVVpK2FZSkpaVDlMNUljYjl6b242SWtZK1RVd29ST1J1aUdEZEl3VjA2VFFLdDMzWkc4dmtvcjVJYURz")
    || ls.get("sfToken").includes("V2tDRTRkTXRJajhMTllJajdOY3pucWlKQXBLV1l2RGR1UWtwL3IyZVd5d2FQSXR6a0NCZTl3dE1FN1p0ZDlZK2tuSlJ1Y0RsWHI1Q3VsNHFCdWZqTFZzVzN3ZWZHR2lNUUt3K05ka1hSQjU1a1FVTDArMW14MWFkS2pweDRJa2ExR001SzFZcFJoTjB6T1ZSR3M3ZEtBPT0=")){
      return navigate('/auth',{state:{to:'/history'}})
    }

    if(renderCounter === 0){
      if(location.state?.isMembership === false)
        navigate("/membership",{state: location.state})
      else{
        fetchReview();
        fetchDetails(query.transactionID);
      }
      if(bodyHeightRef){
        setBodyHeight(bodyHeightRef.current.clientHeight)
      }
    }

    if(pmName === "PENDING" || status !== "2"){
      setButtonBehavior(disabledButton)
    }else{
      if(ls.get("sfReview") && renderCounter === 0){
        setButtonBehavior(ls.get("sfReview"))
      }
    }

    ls.set("sfReview", buttonBehavior)

    setRenderCounter(renderCounter + 1)


  }, [pmName, status]);

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  async function handleChangePaymentMethod(){
    let processData = trx
    processData.totalAfterRounding = totalAfterRounding 
    processData.paymentWay = "Change Method"
    processData.transactionID = query.transactionID
    navigate("/payment", {state: {...processData}})
  }

  async function handleCancelOrder(){
    const data = {
      transactionID: query.transactionID,
      status: 3, 
      cancel_notes: notes
    }

    const cancel = await cancelOrder(data)

    if(cancel.success === "1" || cancel.success === 1){
      setToastOpen(true)
      setToastStatus(cancel.success)
      setMsg(cancel.msg)
      setTimeout(()=>{
        navigate("/order")
      },1200)
    } else {
      setToastOpen(true)
      setToastStatus(cancel.success)
      setMsg(cancel.msg)
      setNotes("")
      setOpenCancelNotes(false)
      setCancelConfirmation(false)
      setClickCancel(false)
      setTimeout(()=>{
        setToastOpen(false)
      },1200)
    }

  }

  async function fetchReview() {
    setLoading(true); 
    await getAllByPartnerID(ls.get("partnerID"), query.transactionID).then((response) => {
      setReview(response["reviews"])

      setLoading(false);

      const filtered = response["reviews"].filter((item) => {
        return item.name === ls.get("redux_localstorage_simple").userData[ls.get("redux_localstorage_simple").userData.length - 1].name})

      if(filtered.length > 0){
        setButtonBehavior(disabledButton)
      }
      
      if(filtered.length === 0){
        setButtonBehavior(enabledButton)
      }
    });
  }

  async function fetchDetails(id) {
    setLoading(true);
    await getDetails({ id: id }).then((response) => {
      setLoading(false);
      setTrx(response)

      if(response.phone !== ls.get("redux_localstorage_simple").userData[ls.get("redux_localstorage_simple").userData.length - 1].phone){
        setButtonBehavior(disabledButton)
        setOverlayAccessDenied(true)
      }

      let dataDetails = response.details;
      let variants = "";
      let subtotalItem = 0;
      let specDiscItem = 0;
      let chargeT = 0;
      let taxT = 0;
      let serviceT = 0;
      let totalSub = 0;
      let specialDiscT = 0;
      let total = 0;
      let programDiscount = 0;
      let promo = 0;

      if (dataDetails.length > 0) {
        dataDetails.forEach((item) => {
          if (item.variant) {
            variants = item.variant;
            variants = variants.replace("[", "{");
            variants = variants.slice(0, -1) + "}";
            variants = JSON.parse(variants.replace(/'/gi, '"'));
            variants = variants.variant;
          }
          let variantString = [];

          if (variants.length > 0) {
            variants.forEach((item) => {
              item.detail.forEach((val) => {
                variantString.push(val.name);
              });
            });
          }
          item.variant =
            variantString.length > 0
              ? variantString.toString().replaceAll(",", ", ")
              : "";

          subtotalItem += parseInt(item.harga);
        //   specDiscItem += parseInt(item.diskon_spesial);
        });

        programDiscount = response.program_discount;
        promo = parseInt(response.promo) ?? 0
        specialDiscT = parseInt(response.specialDiscount);
        totalSub = subtotalItem - specialDiscT - programDiscount - promo;
        chargeT = Math.ceil((totalSub * response.chargeUR) / 100);
        serviceT = Math.ceil((totalSub * response.service) / 100);
        taxT = Math.ceil(((totalSub + serviceT) * response.tax) / 100);
        total = totalSub + chargeT + taxT + serviceT;

        setCharge(response.chargeUR);
        setSpecialDiscount(response.specialDiscount);
        setTax(response.tax);
        setService(response.service);
        setTotalCharge(chargeT);
        setTotalSpecialDisc(specialDiscT);
        setTotalTax(taxT);
        setTotalService(serviceT);
        setSubtotal(subtotalItem);
        setSpecialDiscount(specialDiscT);
        setTotal(total);
        setTotalAfterRounding(total + parseInt(response.rounding));
        setRounding(parseInt(response.rounding));
        setProgramDisc(programDiscount);
        setProgramTitle(response.program_title);
      }

      setStatus(response.transactionStatus);

      if(response.transactionStatus !== "2"){
        setButtonBehavior(disabledButton)
      }
      setTime(response.time);
      setPmName(response.pmName);
      if(response.pmName === "PENDING"){
        setButtonBehavior(disabledButton)
      }
      setDetails(dataDetails);
    });
  }

  let itemCard =
    details &&
    details.map((x, i) => {

      let detail = details[i].thumbnail;

      return (
        <Grid
          container
          style={{
            marginBottom: 10,
            // borderTop: "0.2px solid #c0c0c0",
            // borderBottom: "0.2px solid #c0c0c0",
          }}
          key={i}
        >
          <Grid item xs={3}>
            <img
              src={
                details[i].thumbnail === ""
                  ? "../assets/img/image-not-found.png"
                  : detail
              }
              style={{
                borderRadius: 100,
                objectFit: "cover",
                width: 75,
                height: 75,
              }}
              alt="product"
              onError={(e)=>{
                e.target.src="../assets/img/image-not-found.png"
              }}
            />
          </Grid>
          <Grid item xs={9} textAlign="left">
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <Typography> {details[i].nama}</Typography>
                <Typography align="right"> {" x" + details[i].qty}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{overflowWrap:"break-word"}}>
                  {details[i].variant && <>{details[i].variant}</>}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: 12,
                    color: "gray",
                    width: "65%",
                    fontStyle: "italic",
                  }}
                >
                  {" "}
                  {details[i].notes}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });

  const qrisUrPay = () => {
    navigate(`/qris-ur?transactionID=${query.transactionID}`);
  };

  return (
      overlayAccessDenied ? 
      <Overlay warning={true} routeToGo="/history" routeLabel="Kembali" message="Anda Tidak Memiliki Akses Di Transaksi Ini"/> :
      <DefaultContainer>
        <Toast isOpen={toastOpen} message={msg} status={toastStatus} />

        <Dialog
          open={openCancelNotes}
          PaperProps={{
            style: {borderRadius:"12px"}
          }}
        >
          <Box
            style={{
              height:"auto",
              width:"240px",
              backgroundColor:"white",
              zIndex:9999,
              display:"flex",
              flexWrap: "wrap",
              alignContent:"flex-start",
            }}
            
          >
            <div 
              style={{
                display:"flex",
                width:"100%",
                height:"10%",
                justifyContent:"flex-end"
              }}
            >
              <Button
                onClick={()=>{
                  setOpenCancelNotes(false)
                  }
                }
                style={{
                  paddingTop:"2.5vh"
                }}
              >
                X
              </Button>
            </div>
            <div 
              style={{
                display:"flex",
                width:"100%",
                height:"10%",
                justifyContent:"center",
                alignItems:"baseline",
                paddingTop:"1vh",
                paddingBottom:"1.5vh",
              }}
            >
              <Typography>Alasan Pembatalan</Typography>
            </div>
            <div 
              style={{
                display:"flex",
                width:"100%",
                height:"10%",
                justifyContent:"center",
                alignItems:"baseline",
                paddingTop:"1vh",
                paddingBottom:"1.5vh",
                paddingLeft:"20px",
                paddingRight:"20px"
              }}
            >
              <DefaultTextField
                variant="outlined"
                label="Alasan"
                fullWidth
                autoFocus
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                value={notes}
              />
              {/* <input style={{}} value={notes} onChange={(e) => setNotes(e.target.value)} /> */}
            </div>
            <Button
              style={{
                display:"flex",
                width:"100%",
                flexWrap:"wrap",
                paddingTop:"2vh",
                paddingBottom:"2vh",
                paddingRight:window.innerHeight > window.innerWidth ? "7vw" : "2vw",
                paddingLeft:window.innerHeight > window.innerWidth ? "4vw" : "1.5vw",
                justifyContent:"flex-end",
                color:notes.length > 3 ? "red" : "silver"
              }}
              disabled={notes.length > 3 ? false : true}
              onClick={()=>{
                setClickCancel(true)
                setCancelConfirmation(true)
              }}
              >
                Batalkan Pesanan
            </Button>
          </Box>
        </Dialog>

        <Dialog
          open={cancelConfirmation}
          PaperProps={{
            style: {borderRadius:"12px"}
          }}
        >
          <Box
            style={{
              height:"auto",
              width:"240px",
              backgroundColor:"white",
              zIndex:9999,
              display:"flex",
              flexWrap: "wrap",
              alignContent:"flex-start",
              marginBottom:"2vh"
            }} 
          >
            <div 
              style={{
                display:"flex",
                width:"100%",
                height:"10%",
                justifyContent:"flex-end"
              }}
            >
              <Button
                onClick={()=>{
                  setCancelConfirmation(false)
                  setNotes("")
                  setOpenCancelNotes(false)
                    setClickCancel(false)
                  }
                }
                style={{
                  paddingTop:"2.5vh"
                }}
              >
                X
              </Button>
            </div>
            <div 
              style={{
                display:"flex",
                width:"100%",
                height:"10%",
                justifyContent:"center",
                alignItems:"baseline",
                paddingTop:"1vh",
                paddingBottom:"1.5vh",
                paddingLeft:"20px",
                paddingRight:"20px",
              }}
            >
              <Typography>Anda Yakin Ingin Membatalkan Pesanan?</Typography>
            </div>
            <Button
              variant="contained"
              fullWidth
              style={{
                display:"flex",
                width:"100%",
                flexWrap:"wrap",
                paddingTop:"1vh",
                paddingBottom:"1vh",
                paddingRight:window.innerHeight > window.innerWidth ? "7vw" : "2vw",
                paddingLeft:window.innerHeight > window.innerWidth ? "4vw" : "1.5vw",
                justifyContent:"center",
                marginBottom:"2vh",
                marginLeft:"2vh",
                marginRight:"2vh",
                color:"white"
              }}
              disabled={toastOpen}
              onClick={()=>{
                handleCancelOrder()
              }}
              >
                Ya
            </Button>
            <Button
              variant="contained"
              fullWidth
              style={{
                display:"flex",
                width:"100%",
                flexWrap:"wrap",
                paddingTop:"1vh",
                paddingBottom:"1vh",
                paddingRight:window.innerHeight > window.innerWidth ? "7vw" : "2vw",
                paddingLeft:window.innerHeight > window.innerWidth ? "4vw" : "1.5vw",
                justifyContent:"center",
                marginLeft:"2vh",
                marginRight:"2vh",
                color:"white"
              }}
              disabled={toastOpen}
              onClick={()=>{
                setCancelConfirmation(false)
                setNotes("")
                setOpenCancelNotes(false)
                setClickCancel(false)
              }}
              >
                Tidak
            </Button>
          </Box>
        </Dialog>

        <Box
          style={{
            paddingRight: 15,
            paddingLeft: 15,
            textAlign: "left",
            position: "relative",
            backgroundColor:"white"
          }}
        >
          <Grid
            container
            style={{
              position: "sticky",
              top: 0,
              justifyContent: "center",
              maxWidth: 480,
              background: "#fff",
              padding: "15px 0px",
            }}
          >
            <DefaultHeader title="Detail Transaksi" url={`/history?partnerID=${ls.get("partnerID") ? ls.get("partnerID") : query.partnerID}`} />
          </Grid>
          <Grid container style={{ marginTop: 0, backgroundColor:"white" }}>
            <Grid
              container
              style={{
                position: "sticky",
                top: 60,
                justifyContent: "center",
                width: "100%",
                background: "#fff",
                paddingBottom: 10,
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <ReceiptLongIcon
                  style={{
                    fontSize: 30,
                  }}
                />
                <Typography> {query.transactionID}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 15,
                }}
              >
                <CalendarMonthIcon
                  style={{
                    fontSize: 30,
                  }}
                />
                {time && (
                  <Typography>
                    {(moment(time).locale('id').format('LLL')).replace('pukul', " ")}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 0,
                }}
              >
                <LocalAtmIcon
                  style={{
                    fontSize: 30,
                  }}
                />
                <Typography>
                  <MoneyFormat value={Math.ceil(totalAfterRounding)} />
                </Typography>
              </Grid>
              <Grid
                item
                xs={7}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 0,
                }}
              >
                {(pmName === "DANA" ||
                  pmName === "LINKAJA" ||
                  pmName === "GOPAY" ||
                  pmName === "OVO" ||
                  pmName === "SHOPEEPAY") &&
                  status !== "0" ? (
                  <div
                    style={{
                      position: "relative",
                      width: 40,
                      height: 40,
                    }}
                  >
                    <img
                      alt=""
                      src={`/assets/img/logo/${pmName.toLowerCase()}.png`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "0px center",
                      }}
                    />
                  </div>
                ) : pmName === "TUNAI" ? (
                  <PriceCheckIcon
                    style={{
                      fontSize: 30,
                    }}
                  />
                ) : (
                  pmName === "PENDING" && (
                    <QueryBuilderIcon
                      style={{
                        fontSize: 30,
                      }}
                    />
                  )
                )}
                <Typography>
                  {status === "0" ? "Menunggu Pembayaran" : pmName}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 15, marginBottom:bodyHeight }}>
              {loading ? (
                <center>
                  {/* //buat shimmer card */}
                  <CircularProgress />
                </center>
              ) : details ? (
                itemCard
              ) : (
                <div>notfound</div>
              )}
            </Grid>
          </Grid>
        </Box>
        <Grid
          item
          container
          xs={12}
          ref={bodyHeightRef}
          textAlign="left"
          style={{
            paddingTop: 10,
            paddingBottom: 15,
            paddingRight: 15,
            paddingLeft: 15,
            // margin: '0px 15px',
            position: "fixed",
            bottom: 0,
            maxWidth: 480,
            // width: '100%',
            backgroundColor: "white",
          }}
        >
          <Grid item xs={12}>
            <Typography style={{ fontWeight: 500 }}>
              Detail Tagihan
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: 5,
              display: "flex",
              justifyContent: "space-between",

            }}
          >
            <span>Subtotal</span>
            <span>
              <MoneyFormat value={subtotal} />
            </span>
          </Grid>
          {specialDiscount && totalSpecialDisc ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>Diskon Spesial</span>
              <span>
                <MoneyFormat value={-totalSpecialDisc} />
              </span>
            </Grid>
          ) : (
            ""
          )}

          {charge && totalCharge ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>Biaya Layanan {charge}%</span>
              <span>
                <MoneyFormat value={totalCharge} />
              </span>
            </Grid>
          ) : (
            ""
          )}

          {programTitle && programDisc ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>Diskon ({programTitle})</span>
              <span
                style={{
                  color: "#68BB59",
                }}
              >
                {"- "}
                <MoneyFormat value={programDisc} />
              </span>
            </Grid>
          ) : (
            ""
          )}

          {service && totalService ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>Service {service}%</span>
              <span>
                <MoneyFormat value={Math.round(totalService)} />
              </span>
            </Grid>
          ) : (
            ""
          )}

          {tax && totalTax ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>Tax {tax}%</span>
              <span>
                <MoneyFormat value={Math.round(totalTax)} />
              </span>
            </Grid>
          ) : (
            ""
          )}

          {rounding ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>Total Transaksi</span>
              <span>
                <MoneyFormat value={Math.ceil(total)} />
              </span>
            </Grid>
          ) : (
            ""
          )}

          {rounding ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>Pembulatan</span>
              <span>
                <MoneyFormat value={rounding} />
              </span>
            </Grid>
          ) : (
            ""
          )}

          

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{
                fontWeight: 500,
              }}
            >
              Total
            </span>
            <span
              style={{
                fontWeight: 500,
              }}
            >
              <MoneyFormat value={Math.ceil(totalAfterRounding)} />
            </span>
          </Grid>

          <Grid
          item
          xs={12}
          style={{
            position: "sticky",
            top: 175,
            background: "#fff",
          }}
        >
          {(pmName === "QRIS UR" || ls.get("transactionID") === query.transactionID) && status === "0" && (subtotal || subtotal === 0 || subtotal === "0") ? (
            <Typography
              style={{
                // width: '100%',
                marginTop: 5,
                marginBottom: 5 ,
                textAlign: "center",
                border: "1px solid #1fb0e6",
                padding: "5px 10px",
                borderRadius: 5,
                color: "#1fb0e6",
              }}
              onClick={() =>  {
                if(pmName === "QRIS UR"){
                  qrisUrPay();
                } else if (status === "0" && ls.get("transactionID") === query.transactionID){
                  navigate(
                    `/success?transactionID=${query.transactionID}&status=0`, {
                        state: { urlResponse: ls.get("urlResponse") ?? "" }}
                  )
                }
              }}
            >
              {`${ls.get("transactionID") === query.transactionID ? `Lanjutkan Pembayaran` : `Bayar`}`}
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
          {status === "2" ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                fullWidth
                style={{
                  display: "flex",
                  marginTop: 10,
                  color: "white",
                  background:
                    status === "0" || status === "5" ? "#ffff" : false,
                }}
                onClick={(e) => {
                  navigate(
                    "/create-review?transactionID=" + query.transactionID
                  );
                }}
                disabled={buttonBehavior.disabled}
              >
                Beri Ulasan
              </Button>
            </Grid>
          ) :  status === "0" ? 
          (
            <>
              {
                status === "0" && (subtotal || subtotal === 0 || subtotal === "0") ? 
                <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    display: "flex",
                    marginTop: 2,
                    color: "white",
                  }}
                  disabled={clickCancel}
                  onClick={(e) => {
                    handleChangePaymentMethod()
                  }}
                >
                  Ganti Metode Pembayaran
                </Button>
              </Grid> 
                :
                <></>
              }
              {
                (subtotal || subtotal === 0 || subtotal === "0") ?
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    style={{
                      display: "flex",
                      marginTop: status === "5" ? 10 : 5,
                      color: "white",
                    }}
                    disabled={clickCancel}
                    onClick={(e) => {
                      setOpenCancelNotes(true)
                    }}
                    color="error"
                  >
                    Batalkan Pesanan
                  </Button>
                </Grid>
                :
                <></>
              }
            </>
          )
          :(
            <div></div>
          )}
        </Grid>
      </DefaultContainer>
  );
}
